// TwilioContext.js
import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Device } from '@twilio/voice-sdk';
import { getTokens } from 'src/api/twilioconference';
import PropTypes from 'prop-types';
// api
import { usePostTwilioConference } from 'src/api/twilio';
import { useBoolean } from 'src/hooks/use-boolean';
// firebase
import { DATABASE } from 'src/firebase';
import { ref, onChildRemoved } from "firebase/database";
// api
import { endpoints, postFetcher } from 'src/utils/axios';
import { useSelector } from 'react-redux';
import { useReadUser } from 'src/api/users';
// Crea el contexto
const TwilioContext = createContext();


// Hook personalizado para utilizar el contexto
export const useTwilio = () => useContext(TwilioContext);

// Provider del contexto
export const TwilioProvider = ({ children }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [device, setDevice] = useState(null);
    const [call, setCall] = useState(null);
    const [isReceivingCall, setIsReceivingCall] = useState(false);
    const { conferenceTrigger } = usePostTwilioConference();
    const [phoneNumberCalling, setPhoneNumberCalling] = useState('');
    const [isRecording, setIsRecording] = useState(true);
    const [isHold, setIsHold] = useState(false);
    const [isMute, setIsMute] = useState(false);
    const [incomingCall, setIncomingCall] = useState(null);
    const [callSids, setCallsids] = useState([]);
    const [currentCall, setCurrenCall] = useState('');
    const [direction, setDirection] = useState('');
    const [Participants, setParticipants] = useState([]);
    const [conferenceSid, setConferenceSid] = useState('');
    const onCall = useBoolean();
    const isCalling = useBoolean();
    const isCallIncoming = useBoolean(false);
    const [userTwilioPhone, setTwilioNumber] = useState('');
    const user = useSelector((state) => state.user);
    const userName = user.user.id;
    const { data: userOwner } = useReadUser(userName);
    const userTwilioSid = user.user.twilio_sid;
    const openCompose = useBoolean();
    // const userTwilioPhone = user.user.twilio_phone;

    const resetComponent = useCallback(() => {
        onCall.onFalse();
        isCalling.onFalse();
        isCallIncoming.onFalse();
        setParticipants([])
        setConferenceSid('');
        setDirection('')
        setCurrenCall('')
        setCallsids([])
        setIncomingCall(null);
        setIsMute(false)
        setIsHold(false)
        setIsRecording(true);
        setPhoneNumberCalling('')
        setIsReceivingCall(false);
        setCall(null);
        setPhoneNumber('')
    }, [onCall, isCalling, isCallIncoming])

    // twilioDevice 
    useEffect(() => {
        const fetchTokenAndSetupDevice = async () => {
            try {
                // isCalling.onTrue();
                // setParticipants([{ sid: "123456789", phone: '6442531301', muted: false }])
                console.log("TWILIO PROVIDER:", userOwner)
                const token = await getTokens({ identity: userName, twilioSid: userTwilioSid });
                const deviceNew = new Device(token, {
                    codecPreferences: ['opus', 'pcmu'],
                });

                deviceNew.register();

                deviceNew.on('ready', () => {
                    console.log('TWILIO PROVIDER: Twilio.Device Ready');
                    setDevice(deviceNew);
                });
                // Escuchar evento de desconexión
                deviceNew.on('disconnect', () => {
                    console.log('TWILIO PROVIDER: Call ended');
                    resetComponent()
                });

                // Otros eventos
                deviceNew.on('connect', () => {
                    console.log('TWILIO PROVIDER: connect El cliente ha contestado la llamada');
                });
                deviceNew.on('cancel', () => {
                    console.log('TWILIO PROVIDER: Call canceled');
                });

                deviceNew.on('registered', () => {
                    console.log('TWILIO PROVIDER: The device is ready to receive incoming calls.');
                });

                deviceNew.on('offline', () => {
                    console.log('TWILIO PROVIDER: The device is offline.');
                });

                deviceNew.on('incoming', connection => {
                    console.log("TWILIO PROVIDER: INCOMING CALL")
                    setIsReceivingCall(true);
                    setCall(connection);
                    setPhoneNumberCalling(connection.parameters.From);
                    isCallIncoming.onTrue();
                    onCall.onTrue();
                    setIncomingCall(connection);
                    setCallsids([{ sid: connection.parameters.CallSid }])
                    connection.on('accept', async () => {
                        onCall.onTrue();
                        setDirection("inbound");
                        const { status, data } = await postFetcher(endpoints.twilio.conference.participants,
                            {
                                arg: {
                                    callSid: connection.parameters.CallSid,
                                    userTwilioSid
                                }
                            });
                        if (status === 'success') {
                            const prevCallSids = [];
                            data.forEach(participant => { prevCallSids.push({ sid: participant.callSid }) });
                            setCallsids(prevCallSids)
                        }
                    });
                    connection.on('disconnect', () => {
                        console.log("TWILIO PROVIDER: disconnect")
                        resetComponent()
                    });
                    connection.on('cancel', () => {
                        console.log("TWILIO PROVIDER: cancel")
                        resetComponent()
                    });
                });

                setDevice(deviceNew);

                deviceNew.on('error', (error) => {
                    console.error(`Twilio.Device Error: ${error.message}`);
                });

            } catch (error) {
                console.error('Failed to fetch token:', error);
            }
        };


        fetchTokenAndSetupDevice();


        // Limpieza: Desregistrar eventos y destruir el dispositivo
        return () => {
            if (device) {
                device.destroy();
            }
        };
        // eslint-disable-next-line
    }, []);

    // ParticipantsUseEfect
    useEffect(() => {
        // if (onCall.value === true && Participants.length > 1) {

        console.log("Twilio Provider: ", Participants)
        const callsRef = ref(DATABASE, `Active_Twilio_Conferences/${conferenceSid}/`);

        const unsubscribe = onChildRemoved(callsRef, (snapshot) => {
            const removedItem = snapshot.val();
            console.log('TWILIO PROVIDER: Item removed:', removedItem);
            if (removedItem) {
                const updatedParticipants = Participants.filter(participant => participant.sid !== removedItem.CallSid);
                // Actualizas el estado o la variable con el array filtrado
                setParticipants(updatedParticipants);
            }

        })


        // }
        // Limpia la suscripción cuando el componente se desmonte
        return () => unsubscribe();
        // eslint-disable-next-line
    }, [conferenceSid]);

    // changePhoneNumber
    useEffect(() => {
        console.log(userOwner.twilio_phone)
        setTwilioNumber(userOwner.twilio_phone)
        // eslint-disable-next-line
    }, [userOwner])



    // Memorizar funciones con useCallback para evitar que se recrean en cada render
    const acceptCall = useCallback(() => {
        if (call) {
            call.accept();
            setIsReceivingCall(false);
            isCallIncoming.onFalse();
            setPhoneNumber(call.parameters.From);
            isCalling.onTrue();
            setDirection("inbound");
        }
    }, [call, isCallIncoming, isCalling]);

    const rejectCall = useCallback(() => {
        if (call) {
            call.reject();
            setCall(null);
            setIsReceivingCall(false);
            incomingCall.reject();
            isCallIncoming.onFalse();
            setPhoneNumber('');
            setPhoneNumberCalling('');
        }
    }, [call, incomingCall, isCallIncoming]);

    const makeCall = useCallback(async (To) => {
        if (device) {
            setPhoneNumber(To)
            if(To === userTwilioPhone || phoneNumber === userTwilioPhone){
                return;
            }
            const prevCallSids = []
            const response = await conferenceTrigger({ to: To, accountSid: userTwilioSid, from: userTwilioPhone });
            const outboundCallSid = { sid: response.data.callSid }
            isCalling.onTrue();
            setParticipants([{ sid: response.data.callSid, phone: To, muted: false, inCall: true, loadingMute: false, loadingRemove: false }])
            console.log("twilio-provider", response.data)
            setConferenceSid(response.data.conferenceSid)
            setCurrenCall(outboundCallSid);
            prevCallSids.push(outboundCallSid)
            const outboundCall = await device.connect({ params: { To, callerId: userTwilioPhone } });
            setCall(outboundCall);
            onCall.onTrue();

            outboundCall.on('accept', connection => {
                const clientCallSid = { sid: connection.parameters.CallSid }
                prevCallSids.push(clientCallSid);
                setDirection("outbound");
            });

            outboundCall.on('disconnect', () => {
                isCalling.onFalse();
                setPhoneNumber('');
                setPhoneNumberCalling('');
                resetComponent()
            });

            setCallsids(prevCallSids)
        }
    }, [device, conferenceTrigger, isCalling, onCall, userTwilioSid, resetComponent, userTwilioPhone, phoneNumber]);

    const handleAddParticipant = useCallback(async (newPhoneNumber) => {
        const prevCallSids = callSids;
        const response = await conferenceTrigger({ to: newPhoneNumber, accountSid: userTwilioSid, from: userTwilioPhone });
        setParticipants(prevParticipants => {
            const updatedParticipants = [...prevParticipants];
            updatedParticipants[updatedParticipants.length - 1].sid = response.data.callSid;
            return updatedParticipants;
        });
        // setParticipants([...Participants, { sid: response.data.callSid, phone: newPhoneNumber, muted: false }])
        const outboundCallSid = { sid: response.data.callSid }
        prevCallSids.push(outboundCallSid);
        setCallsids(prevCallSids);

    }, [conferenceTrigger, userTwilioSid, userTwilioPhone, callSids]);

    const endCall = useCallback(async () => {
        if (call) {
            call.disconnect();
            setCall(null);
            const { status } = await postFetcher(endpoints.twilio.conference.end,
                {
                    arg: {
                        conferenceSid,
                        userTwilioSid
                    }
                });
            if (status === 'success') {
                isCalling.onFalse();
                resetComponent()
            }

        }
    }, [call, isCalling, resetComponent, conferenceSid, userTwilioSid]);

    const removeParticipant = useCallback(async (removeSid) => {
        const { status } = await postFetcher(endpoints.twilio.conference.removeParticipants,
            {
                arg: {
                    participantSid: removeSid,
                    userTwilioSid
                }
            });

        if (status === 'success') {
            const updatedParticipants = Participants.filter(participant => participant.sid !== removeSid);
            // Actualizas el estado o la variable con el array filtrado
            setParticipants(updatedParticipants);
        }

    }, [Participants, userTwilioSid])

    const mutedParticipant = useCallback(async (mutedSid, muted) => {
        const { status } = await postFetcher(endpoints.twilio.conference.mute,
            {
                arg: {
                    conferenceSid,
                    callSid: mutedSid,
                    muted,
                    userTwilioSid
                }
            });

        if (status === 'success') {
            console.log("TWILIO PROVIDER: muted sid: ", mutedSid)
            console.log("TWILIO PROVIDER: muted : ", muted)
            const updatedParticipants = Participants.map((participant) => {
                if (participant.sid === mutedSid) {
                    // Modifica el objeto (mutea al participante)
                    return { ...participant, muted: !participant.muted, loadingMute: false };
                }
                return participant;
            });

            // Actualiza el estado con el nuevo array
            setParticipants(updatedParticipants);
        }

    }, [Participants, userTwilioSid, conferenceSid])

    const endConference = useCallback(async () => {
        const { status } = await postFetcher(endpoints.twilio.conference.end,
            {
                arg: {
                    conferenceSid,
                    userTwilioSid
                }
            });
        if (status === 'success') {
            resetComponent();
        }
    }, [conferenceSid, userTwilioSid, resetComponent])

    // Envolver el valor del contexto en useMemo para evitar renders innecesarios
    const value = useMemo(
        () => ({
            device,
            call,
            isReceivingCall,
            isCalling,
            phoneNumberCalling,
            callSids,
            direction,
            onCall,
            currentCall,
            phoneNumber,
            isHold,
            isMute,
            isRecording,
            Participants,
            userTwilioPhone,
            setParticipants,
            setIsHold,
            setIsMute,
            setIsRecording,
            setPhoneNumber,
            acceptCall,
            rejectCall,
            makeCall,
            endCall,
            handleAddParticipant,
            removeParticipant,
            conferenceSid,
            mutedParticipant,
            endConference,
            openCompose
        }),
        [userTwilioPhone, device, call, isReceivingCall, isCalling, phoneNumberCalling, callSids, direction, onCall, currentCall, phoneNumber, isHold,
            isMute, isRecording, Participants, setParticipants, setIsHold, setIsMute, setIsRecording, setPhoneNumber, acceptCall, rejectCall,
            makeCall, endCall, handleAddParticipant, removeParticipant, conferenceSid, mutedParticipant, endConference, openCompose]
    );

    return (
        <TwilioContext.Provider value={value}>
            {children}
        </TwilioContext.Provider>
    );
};

TwilioProvider.propTypes = {
    children: PropTypes.node,
};