import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// auth
import { AuthSubscription } from 'src/auth/subscription';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import ReactivateLayout from 'src/layouts/reactivate';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import PhoneNumberConfirmPurchaseView from 'src/sections/contact-center/view/phone-number-confirm-purchase-view';
import { CallView } from 'src/sections/contact-center/view';
import PhoneNumberActiveView from 'src/sections/contact-center/view/phone-number-active-view';
import LandingListPage from 'src/pages/dashboard/landing/ list';
import LandingCreatePage from 'src/pages/dashboard/landing/add';
import LandingEditPage from 'src/pages/dashboard/landing/edit';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));


// CRM Contacts
const ContactListPage = lazy(() => import('src/pages/dashboard/crm/contact/list'));
const ContactAccountPage = lazy(() => import('src/pages/dashboard/crm/contact/account'));
const ContactCreatePage = lazy(() => import('src/pages/dashboard/crm/contact/new'));
const ContactEditPage = lazy(() => import('src/pages/dashboard/crm/contact/edit'));

// CRM Company
const CompanyListPage = lazy(() => import('src/pages/dashboard/crm/company/list'));
const CompanyAccountPage = lazy(() => import('src/pages/dashboard/crm/company/account'));
const CompanyCreatePage = lazy(() => import('src/pages/dashboard/crm/company/new'));
const CompanyEditPage = lazy(() => import('src/pages/dashboard/crm/company/edit'));

// CRM Leads
const LeadListPage = lazy(() => import('src/pages/dashboard/crm/lead/list'));
const LeadAccountPage = lazy(() => import('src/pages/dashboard/crm/lead/account'));
const LeadCreatePage = lazy(() => import('src/pages/dashboard/crm/lead/new'));
const LeadEditPage = lazy(() => import('src/pages/dashboard/crm/lead/edit'));

// CRM Leads
const OpportunityListPage = lazy(() => import('src/pages/dashboard/crm/opportunity/list'));
const OpportunityAccountPage = lazy(() => import('src/pages/dashboard/crm/opportunity/account'));
const OpportunityCreatePage = lazy(() => import('src/pages/dashboard/crm/opportunity/new'));
const OpportunityEditPage = lazy(() => import('src/pages/dashboard/crm/opportunity/edit'));

// CRM Settings
const SettingListPage = lazy(() => import('src/pages/dashboard/crm/settings/list'));
const SettingCreatePage = lazy(() => import('src/pages/dashboard/crm/settings/new'));
const SettingEditPage = lazy(() => import('src/pages/dashboard/crm/settings/edit'));

// CRM Pipelines
const PipelinesListPage = lazy(() => import('src/pages/dashboard/crm/pipelines/list'));
const PipelinesCreatePage = lazy(() => import('src/pages/dashboard/crm/pipelines/new'));
const PipelinesEditPage = lazy(() => import('src/pages/dashboard/crm/pipelines/edit'));

// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// CONTACT CENTER
const ContactCenterPhoneNumberPage = lazy(() => import('src/pages/dashboard/contact-center/phone-number'));
const A2pPage = lazy(() => import('src/pages/dashboard/contact-center/a2p'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));

// USERS
const UsersListPage = lazy(() => import('src/pages/dashboard/users/users/list'));
const UsersNewPage = lazy(() => import('src/pages/dashboard/users/users/new'));
const UsersEditPage = lazy(() => import('src/pages/dashboard/users/users/edit'));

// USERS TEAMS
const UsersTeamsListPage = lazy(() => import('src/pages/dashboard/users/teams/list'));
const UsersTeamsNewPage = lazy(() => import('src/pages/dashboard/users/teams/new'));
const UsersTeamsEditPage = lazy(() => import('src/pages/dashboard/users/teams/edit'));

// USERS Permissions
const PermissionsListPage = lazy(() => import('src/pages/dashboard/users/permissions/list'));
const UserPermissionsCreatePage = lazy(() => import('src/pages/dashboard/users/permissions/new'));
const UserPermissionsEditPage = lazy(() => import('src/pages/dashboard/users/permissions/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <AuthSubscription>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthSubscription>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'crm',
        children: [
          { element: <></>, index: true },
          {
            path: 'contact',
            children: [
              { element: <ContactListPage />, index: true },
              { path: 'list', element: <ContactListPage /> },
              { path: 'new', element: <ContactCreatePage /> },
              { path: ':id/edit', element: <ContactEditPage /> },
              { path: 'account', element: <ContactAccountPage /> },
            ],
          },
          {
            path: 'company',
            children: [
              { element: <CompanyListPage />, index: true },
              { path: 'list', element: <CompanyListPage /> },
              { path: 'new', element: <CompanyCreatePage /> },
              { path: ':id/edit', element: <CompanyEditPage /> },
              { path: 'account', element: <CompanyAccountPage /> },
            ],
          },
          {
            path: 'lead',
            children: [
              { element: <LeadListPage />, index: true },
              { path: 'list', element: <LeadListPage /> },
              { path: 'new', element: <LeadCreatePage /> },
              { path: ':id/edit', element: <LeadEditPage /> },
              { path: 'account', element: <LeadAccountPage /> },
            ],
          },
          {
            path: 'opportunity',
            children: [
              { element: <OpportunityListPage />, index: true },
              { path: 'list', element: <OpportunityListPage /> },
              { path: 'new', element: <OpportunityCreatePage /> },
              { path: ':id/edit', element: <OpportunityEditPage /> },
              { path: 'account', element: <OpportunityAccountPage /> },
            ],
          },
          {
            path: 'settings',
            children: [
              { element: <SettingListPage />, index: true },
              { path: 'list', element: <SettingListPage /> },
              { path: 'new', element: <SettingCreatePage /> },
              { path: ':id/:owner/edit', element: <SettingEditPage /> }
            ],
          },
          {
            path: 'pipelines',
            children: [
              { element: <PipelinesListPage />, index: true },
              { path: 'list', element: <PipelinesListPage /> },
              { path: 'new', element: <PipelinesCreatePage /> },
              { path: ':id/edit', element: <PipelinesEditPage /> },
            ],
          },
          {
            path: 'settings2',
            children: [
              { element: <LandingListPage />, index: true },
              { path: 'list', element: <LandingListPage /> },
              { path: 'new', element: <SettingCreatePage /> },
              { path: ':id/:owner/edit', element: <SettingEditPage /> }
            ],
          },
        ],
      },
      {
        path: 'landing',
        children: [
          { element: <></>, index: true },
          { element: <LandingListPage />, index: true },
          { path: 'list', element: <LandingListPage /> },
          { path: 'new', element: <LandingCreatePage /> },
          { path: ':id/edit', element: <LandingEditPage /> },
        ],
      },
      {
        path: 'contact_center',
        children: [
          {
            path: 'phone_numbers',
            element: <ContactCenterPhoneNumberPage />,
            index: true,
          },
          { path: 'buy_phone_number', element: <PhoneNumberConfirmPurchaseView /> },
          { path: 'phone_numbers/:id', element: <PhoneNumberActiveView /> },
          { path: 'a2p', element: <A2pPage /> },
          { path: 'calls', element: <CallView /> },
          { path: 'new', element: <></> },
          { path: ':sid/edit', element: <></> },
          { path: 'account', element: <></> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <></>, index: true },
          {
            path: 'users',
            children: [
              { element: <UsersListPage />, index: true },
              { path: 'list', element: <UsersListPage /> },
              { path: 'new', element: <UsersNewPage /> },
              { path: 'edit/:id', element: <UsersEditPage /> },
            ],
          },
          {
            path: 'teams',
            children: [
              { element: <UsersTeamsListPage />, index: true },
              { path: 'list', element: <UsersTeamsListPage /> },
              { path: 'new', element: <UsersTeamsNewPage /> },
              { path: 'edit/:id', element: <UsersTeamsEditPage /> },
            ],
          },
          {
            path: 'permissions',
            children: [
              { element: <PermissionsListPage />, index: true },
              { path: 'list', element: <PermissionsListPage /> },
              { path: 'new', element: <UserPermissionsCreatePage /> },
              { path: ':id/edit', element: <UserPermissionsEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <ReactivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ReactivateLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'reactivate', element: <BlankPage /> },
    ],
  },
];
