/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-brace-presence */

/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { useMemo, useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { LoadingButton } from '@mui/lab'
// Mui
import {
  Fade,
  Menu,
  Stack,
  Dialog,
  Button,
  MenuItem,
  FormControl,
  DialogTitle,
  DialogContent
} from '@mui/material'

import { useBoolean } from 'src/hooks/use-boolean'

import { ConfirmDialog } from 'src/components/custom-dialog'
import FormProvider from 'src/components/hook-form/form-provider'
// Components
import { RHFSelect, RHFTextField } from 'src/components/hook-form'
import SvgColor from 'src/components/svg-color'



// ----------------------------------------------------------------------

export default function KanbanDetails({
  field,
  openDetails,
  onSubmit,
  onCloseDetails,
  onDeleteDetails,
  options,
  landing_page_id
}) {


  const [tempValue, setTempValue] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [values, setData] = useState({})
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {

    setTempValue(null)



    if (Object.keys(field).length > 0) {
      console.log('field => ', field)
      setTempValue(field)
    }
  }, [field])


  const SchamaQuestions = object().shape({
    question: string().required('Question is required'),
    response: object().required('Response is required'),
    required: string().required('Required validation is required')
  })

  const defaultValues = useMemo(() => ({
    question: tempValue?.question || '',
    response: tempValue?.response || null,
    required: tempValue?.required || '',
  }), [tempValue])

  const methods = useForm({
    resolver: yupResolver(SchamaQuestions),
    defaultValues,
    mode: "onSubmit",
  })

  const {
    watch,
    setValue: setValue2,
    handleSubmit: handleSubmit2,
    formState: { isSubmitting },
  } = methods

  const confirm = useBoolean()

  const onSave = () => {

    console.log('questions => ', field, values)

    const value = { ...field, ...values }


    console.log('VALUES => ', value)
    onSubmit(value)
    setData({})
    setTempValue(null)
  }

  const handleMenuClose = (option) => {

    setData((prevTempPage) => ({
      ...prevTempPage,
      more: option
    }))

    setValue2('more', option)

    setAnchorEl(null)

    // if (option === 'remove_from_form') {
    onDeleteDetails(field, option, field.id)
    // }
  }

  const handleChange = (event) => {

    setData((prevTempPage) => ({
      ...prevTempPage,
      [event.target.name]: event.target.value
    }))

    setValue2('question', event?.target?.value)
  }

  const handleChangeRequired = (event) => {

    setData((prevTempPage) => ({
      ...prevTempPage,
      [event.target.name]: event.target.value.toString()
    }))

    setValue2('required', event?.target?.value.toString())
  }


  const handleChangeResponse = (event, value) => {

    const index = options.findIndex(item => item.field_label === event.target.value)



    if (index <= -1) {
      return
    }

    setData((prevTempPage) => ({
      ...prevTempPage,
      'response': options[index]
    }))

    setValue2('response', options[index])
  }

  useEffect(() => {
    if (tempValue) {
      console.log(tempValue)

      setValue2('question', tempValue.question)
      setValue2('response', tempValue.response)
      setValue2('required', tempValue.required)
      // setValue({
      //   question: tempValue.question,
      //   response: tempValue.response,
      //   required: tempValue.required
      // })
    }
  }, [tempValue])

  const sendForm = (data, type) => {
    console.log(data)
    if (type === 'landing-questions') {
      onSendSubmit()
    }
  }

  const getIsAdminQuestion = (option) => {


    let type = false
    for (let i in options) {
      const key = options[i]

      if (key.id === option.id) {
        type = key.created_by == 'Sales Rush'
      }
    }

    return type
  }


  const onSendSubmit = handleSubmit2(async data => {

    onSave()
  })

  const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  )

  return (
    <>
      <Dialog
        open={openDetails}
        onClose={onCloseDetails}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          {tempValue ? "Edit" : "Create a New Question"}
        </DialogTitle>

        <DialogContent>


          <FormProvider methods={methods} onSubmit={onSendSubmit}>
            <Stack

              sx={{
                pt: 1,
                pb: 3,
              }}
            >
              <Stack direction="column">

                <Stack sx={{ mb: 2 }}>
                  <FormControl fullWidth>

                    <RHFTextField
                      fullWidth
                      name="question"
                      label="Form Question"
                      value={values?.question || tempValue?.question || ''}
                      onChange={(data) => handleChange(data)}
                    />
                  </FormControl>
                </Stack>

                <Stack sx={{ pb: 2 }}>
                  <FormControl fullWidth>

                    <RHFSelect
                      name="response"
                      label="Response"
                      value={values?.response?.field_label || tempValue?.response?.field_label || ''}
                      defaultValue={tempValue?.response?.field_label || ''}
                      onChange={handleChangeResponse}

                    >


                      {options?.sort((a, b) => a.field_label !== b.field_label ? a.field_label < b.field_label ? -1 : 1 : 0)?.map((option) => (
                        <MenuItem key={option.field_label} value={option.field_label} sx={{ justifyContent: 'space-between' }}>

                          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                            {option.field_label} {getIsAdminQuestion(option) && <img src={icon('ic_landing')?.props?.src} />}
                          </div>



                        </MenuItem>
                      ))}
                    </RHFSelect>

                  </FormControl>
                </Stack>



                <Stack sx={{ pb: 2 }}>
                  <FormControl fullWidth>
                    <RHFSelect
                      name="required"
                      label="Required"
                      value={values?.required?.toString() || tempValue?.required?.toString() || ''}
                      defaultValue={tempValue?.required?.toString() || ''}
                      onChange={handleChangeRequired}

                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </RHFSelect>
                  </FormControl>
                </Stack>
              </Stack>

              <Stack>

                <Stack direction="row" spacing={1.5} sx={{ justifyContent: tempValue ? 'space-between' : 'right' }}>


                  {
                    tempValue && (
                      <>

                        <Button
                          id="fade-button"
                          aria-controls={open ? 'fade-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          color="inherit"
                          variant="outlined"
                          sx={{ width: 120 }}
                        >
                          More
                        </Button>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}

                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          <MenuItem onClick={() => handleMenuClose('remove_from_form')}>Remove From Form</MenuItem>
                          {/* <MenuItem onClick={() => confirm.onTrue()}>Delete Question</MenuItem> */}
                        </Menu>
                      </>
                    )
                  }

                  <Stack direction="row">
                    <Button
                      color="inherit"
                      variant="outlined"
                      onClick={onCloseDetails}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="button"
                      variant="contained"
                      sx={{ ml: 2 }}
                      onClick={(data) => sendForm(data, 'landing-questions')}
                    >
                      {tempValue ? 'Update Changes' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>


                </Stack>




              </Stack>

            </Stack>
          </FormProvider>



        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete question?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleMenuClose('remove_question')
              confirm.onFalse()
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  )
}

KanbanDetails.propTypes = {
  field: PropTypes.object,
  openDetails: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCloseDetails: PropTypes.func,
  onDeleteDetails: PropTypes.func,
  options: PropTypes.any,
  landing_page_id: PropTypes.string
}
