import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthCoverLayout from 'src/layouts/auth/cover';
import { AuthCenteredLayout } from 'src/layouts-latest/auth-centered';

// ----------------------------------------------------------------------

// CLASSIC
const LoginClassicPage = lazy(() => import('src/pages/auth-demo/classic/login'));
const RegisterClassicPage = lazy(() => import('src/pages/auth-demo/classic/register'));
const ForgotPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/forgot-password'));
const VerifyClassicPage = lazy(() => import('src/pages/auth-demo/classic/verify'));
const NewPasswordClassicPage = lazy(() => import('src/pages/auth-demo/classic/new-password'));

// MODERN
const LoginModernPage = lazy(() => import('src/pages/auth-demo/modern/login'));
const RegisterModernPage = lazy(() => import('src/pages/auth-demo/modern/register'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/modern/forgot-password'));
const VerifyModernPage = lazy(() => import('src/pages/auth-demo/modern/verify'));
const NewPasswordModernPage = lazy(() => import('src/pages/auth-demo/modern/new-password'));


// ----------------------------------------------------------------------

const authClassic = {
  path: 'classic',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      // element: (
      //   <AuthClassicLayout>
      //     <LoginClassicPage />
      //   </AuthClassicLayout>
      // ),
      element: (
        <CompactLayout>
          <LoginClassicPage />
        </CompactLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with MinimaAuthClassicLayoutl">
          <RegisterClassicPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordClassicPage /> },
        { path: 'new-password', element: <NewPasswordClassicPage /> },
        { path: 'verify', element: <VerifyClassicPage /> },
      ],
    },
  ],
};

const authModern = {
  path: 'modern',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        /*         <AuthModernLayout>
                  <LoginModernPage />
                </AuthModernLayout> */
        // <AuthCoverLayout
        //     title="Hi, Welcome Back"
        //     images={[
        //       '/assets/images/travel/travel_post_01.jpg',
        //       '/assets/images/travel/travel_post_03.jpg',
        //     ]}
        // >
        //   <LoginModernPage />
        // </AuthCoverLayout>
        <AuthModernCompactLayout>
          <Outlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: '', element: <LoginModernPage /> },
      ],
    },
    {
      path: 'register',
      element: (
        /* <AuthModernLayout>
          <RegisterModernPage />
        </AuthModernLayout> */
        // <AuthCoverLayout
        //   title={`Manage The Job \n More Effectively`}
        //   images={[
        //     '/assets/images/travel/travel_post_01.jpg',
        //     '/assets/images/travel/travel_post_03.jpg',
        //   ]}
        // >
        //   <RegisterModernPage />
        // </AuthCoverLayout>
        <AuthModernCompactLayout>
          <Outlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: '', element: <RegisterModernPage /> },
      ]
    },
    {
      element: (
        <AuthModernCompactLayout>
          <Outlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordModernPage /> },
        { path: 'new-password', element: <NewPasswordModernPage /> },
        { path: 'verify', element: <VerifyModernPage /> },
      ],
    },
  ],
};

export const authDemoRoutes = [
  {
    path: 'auth-demo',
    children: [authClassic, authModern],
  },
];
