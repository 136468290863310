/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */

'use client';

import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

import {
  Card,
  Stack,
  Table,
  Button,
  Tooltip,
  Container,
  TableBody,
  IconButton,
  TableContainer
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'src/redux/store';

import { useListCatalogs } from 'src/api/catalogs/catalogs'
import { field_types, object_types } from 'src/_mock/_crm-fields';
import { useLandingPagesList, useDeleteLandingPages, useActivedLandingPages } from 'src/api/landingpages'
import { useReadAllCRMFieldsByUser } from 'src/api/user_fields';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import LandingTableRow from '../landing-table-row';
import FieldTableToolbar from '../landing-table-toolbar'
import LandingTableFiltersResult from '../landing-table-filters-result';

// ----------------------------------------------------------------------

export const LANDING_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];


const TABLE_HEAD = [
  { id: 'name', label: 'Page Name' },
  { id: 'user_level', label: 'Url Path', width: 180 },
  { id: 'brokeragename', label: 'Page Loads', width: 220 },
  { id: 'save_submission', label: 'Submissions', width: 180 },
  { id: 'created_by', label: 'Created By' },
  { id: '', width: 88 },
];

const defaultFilters = {
  created: '',
  status: '',
  label: '',
};

// ----------------------------------------------------------------------

export default function LandingListView() {

  const { user } = useSelector((state) => state.user);

  const { data: catalogs } = useListCatalogs()
  const { data: fields } = useReadAllCRMFieldsByUser(user.organization_id, user.user_level);
  const { enqueueSnackbar } = useSnackbar();

  const table = useTable({ defaultOrderBy: 'name', defaultRowsPerPage: 25 })

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState([]);

  // const { usersdata, usersLoading } = useGetUsers();
  const { landingdata, landingLoading, refreshData } = useLandingPagesList(user?.id)
  const { mutate: deleteLandingPages } = useDeleteLandingPages()
  const { mutate: activedLandingPages } = useActivedLandingPages()
  // const { data: fields } = useListCRMSystemFields()
  const [resetFilters, setResetFilters] = useState(false)
  const [usersLevels, setUsersLevels] = useState([])
  const [filters, setFilters] = useState(defaultFilters)
  const [createdOptions, setCreatedOptions] = useState([]);

  const handleResetFilters = useCallback(() => {

    setFilters((prevState) => ({
      ...prevState,
      ...{
        created: '',
        status: '',
        label: '',
      }
    }));

    setResetFilters(true);
  }, [])

  const handleResetResetFilters = useCallback(() => {

    setResetFilters(false)
  }, [])

  useEffect(() => {
    // if (!landingLoading) {
    console.log('data landing', landingdata)
    setTableData(landingdata);
    // }
  }, [landingdata]);


  useEffect(() => {
    if (fields.length) {
      // setTableData(fields);
      // Set created by options
      const created = fields.map((field) => field.created_by);
      const uniqueCreated = [...new Set(created)];
      // Now we need to set the createdOptions with value and label object
      setCreatedOptions(
        uniqueCreated.map((item) => ({
          value: item,
          label: item,
        })));
    }
  }, [fields]);


  const resetTable = useCallback(() => {
    refreshData().then(response => {
      console.log('response => ', response)
    })
  }, [])


  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 56 : 56 + 20;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setResetFilters(true);
  }, []
  );


  const handleDeleteRow = async (id) => {

    // const deleteRow = tableData.filter((row) => row.id !== id);

    await deleteLandingPages({ status: 'inactive' }, id)

    enqueueSnackbar('Deactivation success!');

    setTableData(tableData);
    resetTable()

    table.onUpdatePageDeleteRow(dataInPage.length);
  }


  const handleActivateRow = async (id) => {

    // const deleteRow = tableData.filter((row) => row.id !== id);

    await activedLandingPages({ status: 'active' }, id)

    enqueueSnackbar('Deactivation success!');

    setTableData(tableData);
    resetTable()

    table.onUpdatePageDeleteRow(dataInPage.length);
  }



  const handleDeleteRows = useCallback(async (id) => {


    for (let i in id) {
      deleteLandingPages({ status: 'inactive' }, id[i])
    }

    enqueueSnackbar('Delete success!');

    setTableData(tableData);

    table.onUpdatePageDeleteRows({
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });


  }, [dataFiltered.length, dataInPage.length, enqueueSnackbar, table, tableData]);

  const handleEditRow = useCallback(
    (id, type) => {
      router.push(paths.dashboard.landing.edit(id, type));
    },
    [router]
  );


  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Landing Pages', href: paths.dashboard.landing.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.landing.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Landing Page
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 3 },
          }}
        />

        <Stack spacing={5}>
          <Card sx={{ padding: 2, mb: 2 }}>

            <FieldTableToolbar
              filters={filters}
              onFilters={handleFilters}
              resetFilters={resetFilters}
              handleResetFilters={handleResetResetFilters}
              createdOptions={createdOptions}
              objectOptions={object_types}
              fieldOptions={field_types}
              sx={{ pb: 1.5 }}
            />


            {canReset && (
              <LandingTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                onResetFilters={handleResetFilters}
                //
                results={dataFiltered.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}




            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={dataFiltered.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    dataFiltered.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={dataFiltered.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        dataFiltered.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {landingLoading ? (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} avatar={false} />
                      ))
                    ) : (
                      <>

                        {console.log('dataFiltered', dataFiltered)}

                        {dataFiltered
                          .slice(
                            table.page * table.rowsPerPage,
                            table.page * table.rowsPerPage + table.rowsPerPage
                          )
                          .map((row) => (
                            <>
                              {console.log('row => ', row)}
                              <LandingTableRow
                                key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                onSelectRow={() => table.onSelectRow(row.id)}
                                onDeleteRow={() => handleDeleteRow(row.id)}
                                onEditRow={() => handleEditRow(row.id, row.created_by)}
                                onActivateRow={() => handleActivateRow(row.id)}
                              />
                            </>
                          ))}
                      </>
                    )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              //
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Stack>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(table.selected);
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

    </>
  );
}

// ----------------------------------------------------------------------


function applyFilter({ inputData, comparator, filters }) {

  try {

    const { created, objects, status, label } = filters

    const stabilizedThis = inputData.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })

    inputData = stabilizedThis.map((el) => el[0])


    if (created) {
      inputData = inputData.filter((item) => item.created_by === created);
    }

    if (status !== '') {
      inputData = inputData.filter((item) => status === item.status)
    }

    if (label !== '') {
      inputData = inputData.filter(item => item.page_name.toLowerCase().indexOf(label.toLowerCase()) !== -1)
    }

    return inputData
  }
  catch (err) {
    console.log(err)
    return []
  }
}
