import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { SALESRUSHAPI } from 'src/config-global';
// utils
import { fetcher, postFetcher } from 'src/utils/axios';

export function useNewUser() {
  const createData = async (url, newData) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (newData) => {
      const url = `${SALESRUSHAPI.api_url}/users/create`;
      const updatedData = await createData(url, newData);
      mutate(url);
      return updatedData;
    },
  };
}

export function useReadUser(id) {
  const URL = id ? `${SALESRUSHAPI.api_url}/user/${id}` : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data?.user || {},
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && (!data || data.length === 0),
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useReadUserSub(id) {
  const URL = id ? `${SALESRUSHAPI.api_url}/user/${id}` : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data || {},
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && (!data || data.length === 0),
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useReadAllUsers(permission, organization, id) {
  const URL = id ? `${SALESRUSHAPI.api_url}/users/all/${permission}/${organization}/${id}` : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data?.data || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && (!data || data.length === 0),
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useUpdateUser() {
  const updateData = async (url, newData) => {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (id, newData) => {
      const url = `${SALESRUSHAPI.api_url}/users/update/${id}`;
      const updatedData = await updateData(url, newData);
      mutate(url);
      return updatedData;
    },
  };
}

export function useDeleteUser() {
  const deleteData = async (url) => {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (id) => {
      const url = `${SALESRUSHAPI.api_url}/users/delete/${id}`;
      const updatedData = await deleteData(url);
      mutate(url);
      return updatedData;
    },
  };
}

export function useSearchUsers(organization_id, params) {
  const queryString = new URLSearchParams(params).toString();
  const URL = `${SALESRUSHAPI.api_url}/search/users/${organization_id}?${queryString}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    dedupingInterval: 60000,
  });

  const memoizedValue = useMemo(
    () => ({
      data: data?.data || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && (!data || !data.data || data.data.length === 0),
      total: data?.total || 0,
      page: data?.page || 0,
      pageTotal: data?.pageTotal || 1,
      limit: data?.limit || 5,
      refreshData: () => mutate(URL), // Necessary for the use refresh data every time to render the component
    }),
    [data, error, isLoading, isValidating, URL]
  );

  return memoizedValue;
}

// Function to update the user
export function useResendEmailUser() {
  const URL = `${SALESRUSHAPI.api_url}/users/resend-email`;
  // Use the useSWR hook to fetch the data
  const { data, error, isValidating } = useSWR(null, fetcher, {
    shouldRetryOnError: false,
  });
  // Function to resend the email
  const resendEmail = async (id) => {
    const endpoint = `${URL}/${id}`;
    try {
      // Realizar la solicitud POST
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Check if the response is ok
      if (!response.ok) {
        throw new Error('Error al reenviar el correo electrónico');
      }
      // Get the data
      const dataResen = await response.json();
      // Use mutate to update the data
      mutate(endpoint);
      // Send
      return dataResen;
    } catch (errorResen) {
      console.error('Error:', errorResen);
      throw errorResen;
    }
  };
  // Return the data, error, isValidating and the function to resend the email
  return {
    data,
    error,
    isValidating,
    mutate: resendEmail,
  };
}

export function useUpdateUserPermission() {
  const updateData = async (url, newData) => {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (id, newData) => {
      const url = `${SALESRUSHAPI.api_url}/users/update/${id}/permission`;
      const updatedData = await updateData(url, newData);
      mutate(url);
      return updatedData;
    },
  };
}
